@mixin font-face($font-family, $font-suffix, $font-weight, $font-style) {
  @font-face {
    font-family: $font-family;
    src: url($font-suffix) format("truetype"),
    url($font-suffix) format("opentype");
    font-weight: $font-weight;
    font-style: $font-style;
  }
}

@include font-face(
                Nunito,
                "../../assets/fonts/Nunito-Bold.ttf",
                700,
                normal
);

@include font-face(
                Nunito,
                "../../assets/fonts/Nunito-Regular.ttf",
                400,
                normal
);
