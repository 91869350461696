@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";

.PartnerBox {
  align-items: center;
  border: 1px solid #BEBEBE;
  cursor: pointer;
  display: flex;
  height: 308px;
  justify-content: center;
  width: 352px;

  img {
    height: 227.73px;
    width: 227.73px;
  }

  &:hover {
    background-color: #BEBEBE;
  }
}
