$text_primary_light_color: #FFFFFF;
$base_font_size: 20px;

$very_dark_blue: #000625;
$dark_blue: #000D50;
$orange: #F28300;
$light_orange: #F8A547;
$grey: #3D3D3D;
$fluorescent_green: #73F48F;

$navbar_color: #000D50;
$navbar_height: 131px;

$contained_button_background: #FF9900;
$contained_button_background_hover: #de8501;
$contained_green_button_background: #009861;
$contained_green_button_background_hover: rgba(0, 152, 97, 0.79);

$outlined_button_background: rgba(0,0,0,0);
$outlined_button_background_hover: rgba(0,0,0,0.3);

$breakpoints: (
  "mobile-small": 400px,
  "mobile-large": 576px,
  "tablet": 768px,
  "tablet-portrait": 992px,
  "desktop-small": 1200px,
  "desktop-medium": 1400px,
  "desktop-large": 1600px,
  "desktop-extra-large": 1800px
);