@import "../../assets/scss/variables";
@import "../../assets/scss/fonts";

.LawSuit {
  background-color: #0F9DED;
  height: 100%;
  padding: 100px 63px 200px 63px;

  h2 {
    background: linear-gradient(0deg, rgba(4, 53, 81, 0.19) 65%, #0F9DED 10%);
    border-bottom: 15px solid #9DF9FF;
    color: #9DF9FF;
    font-family: 'Nunito', sans-serif;
    font-size: 48px;
    font-weight: 700;
    height: 60px;
    line-height: 65px;
    margin-bottom: 79px;
    width: 367px;
  }

  .infos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .info-full {
      align-items: center;
      display: flex;
      height: 400px;
      justify-content: center;
      position: relative;
      width: 550px;

      p {
        font-family: 'Nunito';
        font-size: 80px;
        font-style: normal;
        font-weight: 700;
        line-height: 109px;
        position: absolute;

        &:last-child {
          top: 240px;
          position: absolute;
          font-weight: 400;
          width: 324px;
          text-align: center;
          font-size: 24px;
          line-height: 33px;
        }
      }
    }

    img {
      height: 70%;
      width: 70%;
    }

    .vector-shops {
      height: 50%;
      width: 50%;
    }

    .vector-person {
      height: 60%;
      width: 60%;
    }
  }
}
