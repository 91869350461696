@import "../../assets/scss/variables";
@import "../../assets/scss/fonts";

.Footer {
  background-color: #000625;
  display: flex;
  height: 432px;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  width: 100%;

  .click-footer {
    display: flex;
    padding: 65px 0 0 65px;

    p {
      color: #868585;
    }

    img {
      height: 33px;
      margin: 7px 30px 0 0;
      width: 148px;
    }

    div {
      display: flex;
      flex-direction: column;
    }
  }

  .box-image {
    background-position: right;
    background-repeat: no-repeat;
    background-size: auto 100%;
    height: 100%;
    margin: 0;
    width: 100%;

    img{
      height: 98%;
      margin: 0;
      width: 100%;
    }
  }
}
