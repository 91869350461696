@import "../../assets/scss/variables";

.MainBenefits {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-sizing: border-box;
  padding: 0 63px 0 63px;

  .title {
    display: flex;
    align-items: flex-start;
  }

  .benefits {
    display: flex;
    justify-content: space-between;
  }

  h2 {
    color: $grey;
    background: linear-gradient(0deg, $light_orange 50%, white 50%);
  }


}