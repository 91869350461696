@import "../../assets/scss/variables";

.Navbar {
    position: fixed;
    background-color: $navbar_color;
    box-sizing: border-box;
    height: $navbar_height;
    width: 100%;
    padding: 0 64px 0 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 5px solid rgba(255, 255, 255, 0.56);
    z-index: 99;

    img {
        height: 33px;
    }

    .actions {
        display: flex;
        gap: 30px;
    }
}

.navbar-spacer {
    height: $navbar_height;
}
