@import "../../assets/scss/variables";

.Benefit {
  width: 400px;
  height: 570px;
  box-sizing: border-box;
  background-color: $dark_blue;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;

  flex-direction: column;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  text-align: center;

  h3 {
    text-align: center;
  }

  .description {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}