@import "../../assets/scss/variables";

.Button {
  cursor: pointer;
  border: none;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  font-size: 24px;
  line-height: 33px;
  padding: 24px;

  &.small {
    padding: 16px;
    font-size: 18px;
    line-height: 25px;
  }

  &.contained {
    background-color: $contained_button_background;
  }

  &.contained:hover {
    color: rgba(255, 255, 255, 0.9);
    background-color: $contained_button_background_hover;
  }

  &.outlined {
    border: 1px solid #FFFFFF;
    background-color: $outlined_button_background;
  }

  &.outlined:hover {
    background-color: $outlined_button_background_hover;
    border: 1px solid rgba(255, 255, 255, 0.4);
    color: rgba(255, 255, 255, 0.7);
  }
}