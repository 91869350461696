@import "../../assets/scss/variables";
@import "../../assets/scss/fonts";

.Pricing {
  align-items: center;
  background-color: $orange;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 0 63px 100px 63px;

  div:first-child {
    margin-top: 170px;
  }

  h2 {
    background: linear-gradient(0deg, rgba(4, 53, 81, 0.19) 65%, $orange 10%);
    color: $text_primary_light_color;
    font-family: 'Nunito', sans-serif;
    font-size: 64px;
    font-weight: 700;
    height: 60px;
    margin-bottom: 79px;
    width: 440px;
  }

  p {
    font-family: 'Nunito', sans-serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 41px;
    margin-bottom: 40px;
    width: 684px;

    &:last-child {
      font-size: 20px;
      font-style: italic;
      line-height: 27px;
    }
  }

  .Button {
    background-color: $contained_green_button_background;
    height: 81px;
    margin-top: 90px;
    width: 703px;

    &.contained:hover {
      background-color: $contained_green_button_background_hover;
    }
  }

  img {
    width: 100%;
  }
}
