@import "../../assets/scss/variables";

.AboveTheFold {
  background-color: $very_dark_blue;
  display: flex;
  font-size: 24px;

  .image {
    display: flex;
    align-items: center;
    width: 630px;
    height: calc(100% - 1px);
    background-color: white;

    img {
      position: absolute;
      left: 128px;
    }
  }

  .cta-container {
    display: flex;
    justify-content: center;
    width: calc(100% - 630px);
  }

  .cta {
    width: 603px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    h1 {
      margin-bottom: 27px;
    }

    button {
      margin-top: 111px;

    }
  }
}