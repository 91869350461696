@import "../../assets/scss/variables";
@import "../../assets/scss/fonts";

.OpenShop {
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 237px 0 100px 0;

  p {
    color: #033156;
    font-family: 'Nunito', sans-serif;
    font-size: 70px;
    font-weight: 700;
    line-height: 82px;
    text-align: center;
  }

  .text-small {
    font-size: 50px;
    font-weight: 400;
  }

  .images-shop {
    margin-top: 100px;
    width: 55%;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .box-button {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 100px;

    img {
      height: 40px;
      width: 30px;
    }

    .Button {
      height: 81px;
      margin-top: 20px;
      width: 703px;
    }
  }
}