@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";

.Partners {
  background-color: white;
  height: 100%;
  padding: 161px 0 160px 0;

  .box-title {
    background-color: $fluorescent_green;
    height: 160px;
    padding: 59px 0 0px 80px;
    width: 60%;

    p {
      color: $very_dark_blue;
      font-family: 'Nunito', sans-serif;
      font-size: 2.25rem;
      font-weight: 700;
      line-height: 49px;

      &:last-child {
        font-size: 1.25rem;
        font-weight: 400;
      }
    }
  }

  .partners-section {
    display: flex;
    justify-content: center;
    margin-top: 100px;

    .group-partners {
      display: flex;
      flex-wrap: wrap;
      width: 60%;

      .PartnerBox {
        margin: 10px;
      }
    }
  }
}
